<template>

  <div>
    <floating-loading :shown="!didLoadedData"/>

    <h1>{{ $t('statistics.title') }}</h1>

    <div v-if="statistics.length === 0">{{ $t('statistics.elements.noStatistics') }}</div>

    <v-container>
      <v-row>
        <v-col
            cols="12"
            sm="6"
        >
          <v-text-field
              id="search_tutorial_input"
              v-model="querySearchValue"
              :label="$t('userMessages.elements.searchPlaceholder')"
              clearable
              prepend-icon="search"
              :append-icon="querySearchValue && querySearchValue !== $query.search ? 'check' : null"
              @click:append="applyQueryFilter(); updateComparisonGraph()"
              @click:clear="querySearchValue = ''; applyQueryFilter(); updateComparisonGraph()"
              @keypress.enter="applyQueryFilter(); updateComparisonGraph()"
              @blur="applyQueryFilter(); updateComparisonGraph()"
          />
          <v-radio-group
              id="language_filter"
              v-model="filterByLanguage"
              row
              @change="updateComparisonGraph"
          >
            <v-radio
                :label="$t('userMessages.filter.allLanguages')"
                value="all"
                id="all"
            ></v-radio>
            <v-radio
                v-for="item in languages"
                :key="item"
                :value="item"
                :id="item"
                :class="item"
            >
              <template v-slot:label>
              <span
                  :class="`icon-flag sort-language ${item} active`"
              />
              </template>
            </v-radio>
          </v-radio-group>
        </v-col>
        <v-col
            cols="12"
            sm="3"
        >
          <v-select
              data-cy="sort_tutorials"
              v-model="tutorialsSortBy"
              :items="tutorialsFilterValueItems"
              :label="$t('userMessages.filter.sortTutorials')"
              :prepend-icon="tutorialsFilterPrependIcon"
              @change="updateComparisonGraph"
          >
            <template v-slot:item="{ item }">
              <v-icon :color="item.color" class="mr-2">{{item.icon}}</v-icon>
              {{ item.text }}
            </template>
          </v-select>

        </v-col>
        <v-col
            cols="12"
            sm="3"
        >
          <v-select
              data-cy="tag_filter"
              label="Tag"
              :items="tagFilterValueItems"
              v-model="$query.tag"
              prepend-icon="label"
              clearable
          >
            <template v-slot:item="{ item }">
              <v-icon :color="item.color" class="mr-2">{{ item.value === -1 ? 'label_off' : 'label' }}</v-icon>
              {{ item.text }}
            </template>
          </v-select>
          <v-row align="center" id="max_count_wrapper">
            <v-checkbox
                class="max_count_checkbox"
                :label="$t('statistics.elements.maxCount')"
                v-model="filterByCount"
                style="padding-left: 10px; padding-right: 10px"
            ></v-checkbox>
            <v-text-field
                id="max_count_input"
                class="filterCount"
                v-model="queryCountValue"
                :append-icon="queryCountValue && queryCountValue.toString() !== $query.count.toString() ? 'check' : null"
                :disabled="!filterByCount"
                :rules="filterCountRules"
                @click:append="applyQueryCountFilter()"
                @keypress.enter="applyQueryCountFilter()"
                maxlength="5"
                single-line
                style="margin-right: 12px"
            />
          </v-row>
        </v-col>
      </v-row>

      <div v-if="filteredTutorials.length !== 0">
        <v-col
            align="center"
        >
          <div :style="{height: customGraphHeight + 'px'}" >
            <bar-chart-horizontal v-if="isComparisonGraphVisible"
                                  :y-axis-data="comparisonGraph.yAxisData" :series="comparisonGraph.series"
                                  :graphHeight="customGraphHeight.toString()" :graphWidth="graphWidth.toString()"
                                  :axis-label-width="axisLabelWidth"
            ></bar-chart-horizontal>
          </div>
        </v-col>
      </div>

    </v-container>



    <v-card style="margin-top: 10px">
      <v-card-title>
        {{ $t('tutorials.title') }}
      </v-card-title>
      <v-data-table
          id="tutorials_table"
          :headers="headers"
          :items="filteredTutorials"
          hide-default-footer
          disable-pagination
      >
        <template  v-slot:body="{ items }">
          <tbody>
          <template v-if="items.length===0">
            <tr>
              <td colspan="5">{{ $t('elements.noData') }}</td>
            </tr>
          </template>
          <template v-else >
            <tr v-for="(item, idx) in items" :key="idx">
              <td class="tutorial_link" v-on:click="openInNewTab(item.faqItemId)">
                  <p class="inline-block-text">{{ item.faqItemName }}</p>
                  <span :class="`icon-flag ${item.language} smaller`"></span>
              </td>
              <td class="center-align">{{ item.notHelpfulFeedback }}</td>
              <td class="center-align">{{ item.helpfulFeedback }}</td>
              <td class="center-align">{{ item.unfinished }}</td>
              <td class="center-align">{{ item.continued }}</td>
            </tr>
          </template>

          </tbody>
        </template>
      </v-data-table>
    </v-card>

  </div>
</template>

<script>
import FloatingLoading from "@/components/FloatingLoading";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {languages} from '../config';
import {nlpUtils} from "@/utils/nlp";
import {sanitize} from "@/utils/common";
import BarChartHorizontal from "@/components/BarChartHorizontal";

export default {
  name: "Statistics",
  components: {BarChartHorizontal, FloatingLoading},
  mounted() {
    this.scrollToTop();
    this.comparativeTutorialsCountHandler();
    this.loadTags();
  },
  data() {
    return {
      querySearchValue: this.$query.search,
      queryTagValue: this.$query.tag,
      queryCountValue: this.$query.count,
      customGraphHeight: 250,
      axisLabelWidth: 420,
      graphWidth: 1200,
      filterByLanguage: "all",

      //comparison graph
      isComparisonGraphVisible: false,
      comparisonGraph: {
        faqItemsIds: [],
        series: [
          {
            name: this.$t('statistics.graphElements.notHelpfulFeedback'),
            type: 'bar',
            data: [],
            label: {
              show: true,
              position: 'right'
            },
          },
          {
            name: this.$t('statistics.graphElements.unfinished'),
            type: 'bar',
            data: [],
            label: {
              show: true,
              position: 'right'
            },
          },
          {
            name: this.$t('statistics.graphElements.helpfulFeedback'),
            type: 'bar',
            data: [],
            label: {
              show: true,
              position: 'right'
            },
          },
          {
            name: this.$t('statistics.graphElements.continued'),
            type: 'bar',
            data: [],
            label: {
              show: true,
              position: 'right'
            },
          },
        ],
        yAxisData: [], // names of the tutorials
      },

      // filter constants
      notHelpful: "notHelpful",
      helpful: "helpful",
      unfinished: "unfinished",
      continued: "continued",

      filterByCount: true,
      filterCountRules: [
        value => value > 0 || this.$t('userMessages.filter.invalidInput')
      ],

      tutorialsSortBy: this.$query.toggleSortTutorials,


      // table
      headers: [
        {
          text: this.$t('tutorials.elements.languageName'),
          align: 'start',
          value: 'faqItemName'
        },
        {
          text: this.$t('statistics.graphElements.notHelpfulFeedback'),
          value: 'notHelpfulFeedback',
          align: 'center'
        },
        {
          text: this.$t('statistics.graphElements.helpfulFeedback'),
          value: "helpfulFeedback",
          align: 'center'
        },
        {
          text: this.$t('statistics.graphElements.unfinished'),
          value: "unfinished",
          align: 'center'
        },
        {
          text: this.$t('statistics.graphElements.continued'),
          value: 'continued',
          align: 'center'
        }
      ]
    }
  },

  watch: {
    queryToggleSortTutorialsValue(newValue){
      if(newValue !== undefined) {
        this.updateComparisonGraph()
        this.$query.toggleSortTutorials = newValue
      }
    },

    '$query.tag': function(newValue, oldValue) {
      if (newValue) {
        this.updateComparisonGraph()
      }
      if(oldValue && !newValue) {
        this.updateComparisonGraph()
      }
    },

    filterByCount: function() {
      this.updateComparisonGraph()
    },

  },

  methods: {
    ...mapActions('statistics', ['loadAllStatistics']),
    ...mapMutations('statistics', ['setAllIsCompared', 'setIsCompared']),
    ...mapActions('tags', ['loadTags']),

    applyQueryFilter() {
      this.$query.search = this.querySearchValue;
    },

    applyQueryCountFilter() {
      if(this.queryCountValue > 0) {
        this.$query.count = this.queryCountValue;
        this.updateComparisonGraph();
      }
    },

    clearGraph(graph) {
      graph.yAxisData = []
      graph.faqItemsIds = []
      for (let i = 0; i < 4; i++) {
        graph.series[i].data = []
      }
    },

    makeGraphVisible(){
      setTimeout(function () {
        this.isComparisonGraphVisible = true
      }.bind(this), 1);
    },

    addTutorialToGraph(tutorial, graph) {
      graph.faqItemsIds.push(tutorial.faqItemId)

      for (let i = 0; i < 4; i++) {
        switch (i) {
          case 0:
            graph.series[0].data.push(tutorial.notHelpfulFeedback);
            break;
          case 1:
            graph.series[1].data.push(tutorial.unfinished);
            break;
          case 2:
            graph.series[2].data.push(tutorial.helpfulFeedback);
            break;
          case 3:
            graph.series[3].data.push(tutorial.continued);
            break;
          default:
            break;
        }
      }

      graph.yAxisData.push(tutorial.faqItemName);
    },

    setGraphSize(numberOfTutorials){
      const baseHeight = 250
      this.customGraphHeight = baseHeight + numberOfTutorials * 60;
    },

    sortTutorialArray(array) {
      let result = array.slice()

      if (this.tutorialsSortBy === this.helpful) {
        result.sort((a, b) => {
          return b.helpfulFeedback - a.helpfulFeedback
        })
      }

      if (this.tutorialsSortBy === this.notHelpful) {
        result.sort((a, b) => {
          return b.notHelpfulFeedback - a.notHelpfulFeedback
        })
      }

      if (this.tutorialsSortBy === this.continued) {
        result.sort((a, b) => {
          return b.continued - a.continued
        })
      }
      if (this.tutorialsSortBy === this.unfinished) {
        result.sort((a, b) => {
          return b.unfinished - a.unfinished
        })
      }

      return result;
    },

    async comparativeTutorialsCountHandler(){
      await this.loadAllStatistics();
      this.updateComparisonGraph();
    },

    updateComparisonGraph(){
      this.isComparisonGraphVisible = false
      this.clearGraph(this.comparisonGraph)

      const number = this.filteredTutorials.length
      this.setGraphSize(number)

      for(let i = number - 1; i >= 0; i--) {
        this.addTutorialToGraph(this.filteredTutorials[i], this.comparisonGraph)
      }
      this.makeGraphVisible()
    },

    scrollToTop() {
      window.scrollTo({
        top: 0,
      })
    },

    openInNewTab(faqItemId) {
      let url = '/tutorials/' + faqItemId;
      window.open(url, '_blank');
    },

  },
  computed: {
    didLoadedData() {
      return this.$store.state.statistics.didLoadedData
    },

    ...mapGetters('statistics', ['statistics']),
    ...mapGetters('tags', ['localizedTags']),

    languages() {
      return languages;
    },
    filteredTutorials() {
      let result = this.statistics.slice();

      result = result.sort((a, b) => a.faqItemName.localeCompare(b.faqItemName))

      result = this.sortTutorialArray(result)

      // filter by free text
      if (this.$query.search) {
        const requiredTokens = nlpUtils.tokenizeWithoutWhitespaces(this.$query.search);
        result = result.filter(item => requiredTokens.every(token => sanitize(item.faqItemName).includes(sanitize(token))))
      }

      // filter by language
      if (this.filterByLanguage === 'uk') {
        result = result.filter(item => item.language === 'uk')
      }

      if (this.filterByLanguage === 'en') {
        result = result.filter(item => item.language === 'en')
      }

      if (this.filterByLanguage === 'ru') {
        result = result.filter(item => item.language === 'ru')
      }

      // filter by a tag
      if (this.$query.tag === -1) { // tutorials without any tag
        result = result.filter(item => item.tagIds.length === 0)
      } else if (this.$query.tag !== null && !isNaN(this.$query.tag)) { // tutorials containing the searched tag
        result = result.filter(item => item.tagIds.includes(this.$query.tag))
      }

      // filter by count
      if(this.filterByCount) {
        if(this.$query.count) {
          result = result.splice(0, this.$query.count)
        }
      }

      return result;
    },

    tagFilterValueItems() {
      const items = this
          .localizedTags(this.language)
          .map(tag => ({
            isTag: true,
            text: tag.name,
            value: tag.id,
            color: tag.color,
          }));
      items.unshift({
        isTag: true,
        text: this.$t('tutorials.elements.withoutTag'),
        value: -1,
      });
      return items;
    },

    tutorialsFilterValueItems() {
      return [
        {
          text: this.$t('statistics.graphElements.notHelpfulFeedback'),
          value: this.notHelpful,
          color: '#f44336',
          icon: 'thumb_down',
        },
        {
          text: this.$t('statistics.graphElements.helpfulFeedback'),
          value: this.helpful,
          color: '#4caf50',
          icon: 'thumb_up',
        },
        {
          text: this.$t('statistics.graphElements.unfinished'),
          value: this.unfinished,
          color: '#ff9800',
          icon: 'block',
        },
        {
          text: this.$t('statistics.graphElements.continued'),
          value: this.continued,
          color: '#2196f3',
          icon: 'play_arrow',
        },
      ];
    },

    tutorialsFilterPrependIcon() {
      const selectedItem = this.tutorialsFilterValueItems.find(item => item.value === this.tutorialsSortBy);
      return selectedItem ? selectedItem.icon : null;
    }

  },
}
</script>

<style scoped>
.filterCount {
  width: 80px;
  padding-top: 5px;
}

.icon-flag {
  cursor: pointer;
}

.sort-language:not(.active) {
  opacity: 0.3;
}

.smaller {
  width: 16px;
  height: 12px;
}

.v-expansion-panel-header > *:not(.v-expansion-panel-header__icon) {
  flex: none;
}

.v-input--selection-controls {
  margin-top: 4px;
}

.custom_size {
  height: 280px;
  width: 1200px;
}

.v-application p {
  margin-bottom: 0;
}

.center-align {
  text-align: center;
}

.inline-block-text {
  display: inline-block;
}

.tutorial_link:hover {
  cursor: pointer;
  font-weight: bold;
}

</style>