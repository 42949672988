<template>
  <div>
    <v-container grid-list-md>
      <v-alert
          color="orange"
          text
          type="warning"
          dismissible
          v-if="currentUserCanAccess('userMessages') && entitiesStatistics['UserMessagesToBeReviewed'].count !== 0"
      >
        <v-input
          style="font-weight: bold"
        >{{!didLoadedData ? "..." : $t('userMessages.uncategorizedMessagesAlert', [this.entitiesStatistics['UserMessagesToBeReviewed'].count]) }}</v-input>
        <v-input
            v-for="message in mostFrequentMessages"
            :key="message.id"
            class="dense"
        >
          {{ message.text }}
        </v-input>
        <v-input v-if="getUserMessagesCount() > 0">{{ `+ ${getUserMessagesCount()} ${$t('userMessages.more')}` }}</v-input>
        <v-btn
            color="orange"
            @click="$router.push('user-messages')"
        >
          {{ $t('userMessages.assignMessageDialog.assign') }}
        </v-btn>
      </v-alert>
      <v-layout wrap>

        <v-flex md6 lg4 v-if="currentUserCanAccess('tutorials')">
          <v-card
              outlined
              v-on:click="$router.push('tutorials')"
          >
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="headline mb-1">
                  {{ $t('tutorials.title') }}
                  <v-chip color="primary ml-1">{{
                      !didLoadedData ? "..." : entitiesStatistics['Tutorials'].count
                    }}
                  </v-chip>
                </v-list-item-title>
                <v-list-item-subtitle>{{ $t('tutorials.description') }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-card-actions>
              <v-btn color="primary" text>{{ $t('buttons.enter') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>

        <v-flex md6 lg4>
          <v-card
              outlined
              v-on:click="$router.push('statistics')"
          >
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="headline mb-1">
                  {{ $t('statistics.title') }}
                  <v-chip color="primary ml-1"> 5
                  </v-chip>
                </v-list-item-title>
                <v-list-item-subtitle>{{ $t('statistics.description') }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-card-actions>
              <v-btn color="primary" text>{{ $t('buttons.enter') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>

        <v-flex md6 lg4 v-if="currentUserCanAccess('tags')">
          <v-card
              outlined
              v-on:click="$router.push('tags')"
          >
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="headline mb-1">
                  {{ $t('tags.title') }}
                  <v-chip color="primary ml-1">{{ !didLoadedData ? "..." : entitiesStatistics['Tags'].count }}</v-chip>
                </v-list-item-title>
                <v-list-item-subtitle>{{ $t('tags.description') }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-card-actions>
              <v-btn color="primary" text>{{ $t('buttons.enter') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>

        <v-flex md6 lg4 v-if="currentUserCanAccess('contacts')">
          <v-card
              outlined
              v-on:click="$router.push('contacts')"
          >
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="headline mb-1">
                  {{ $t('contacts.title') }}
                  <v-chip color="primary ml-1">{{
                      !didLoadedData ? "..." : entitiesStatistics['Contacts'].count
                    }}
                  </v-chip>
                </v-list-item-title>
                <v-list-item-subtitle>{{ $t('contacts.description') }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-card-actions>
              <v-btn color="primary" text>{{ $t('buttons.enter') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>

        <v-flex md6 lg4 v-if="currentUserCanAccess('reports')">
          <v-card
              outlined
              v-on:click="$router.push('reports')"
          >
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="headline mb-1">
                  {{ $t('reports.title') }}
                  <v-chip
                      :class="didLoadedData && entitiesStatistics['NewReports'].count > 0 ? 'red white--text font-weight-black ml-1' : 'primary ml-1'">
                    {{ !didLoadedData ? "..." : entitiesStatistics['NewReports'].count }}
                  </v-chip>
                </v-list-item-title>
                <v-list-item-subtitle>{{ $t('reports.description') }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-card-actions>
              <v-btn color="primary" text>{{ $t('buttons.enter') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>

        <v-flex md6 lg4 v-if="currentUserCanAccess('chatbots')">
          <v-card
              outlined
              v-on:click="$router.push('chatbots')"
          >
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="headline mb-1">
                  {{ $t('chatbotSetting.title') }}
                  <v-chip color="primary ml-1">{{
                      !didLoadedData ? "..." : entitiesStatistics['Chatbots'].count
                    }}
                  </v-chip>
                </v-list-item-title>
                <v-list-item-subtitle>{{ $t('chatbotSetting.description') }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-card-actions>
              <v-btn color="primary" text>{{ $t('buttons.enter') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>

        <v-flex md6 lg4>
          <v-card
              outlined
              v-on:click="$router.push('user-messages')"
          >
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="headline mb-1">
                  {{ $t('userMessages.title') }}
                  <v-chip color="primary ml-1"> {{
                      !didLoadedData ? "..." : entitiesStatistics['UserMessages'].count
                    }}
                  </v-chip>
                </v-list-item-title>
                <v-list-item-subtitle>{{ $t('userMessages.description') }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-card-actions>
              <v-btn color="primary" text>{{ $t('buttons.enter') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>

        <v-flex md6 lg4 v-if="currentUserCanAccess('users')">
          <v-card
              outlined
              v-on:click="$router.push('users')"
          >
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="headline mb-1">
                  {{ $t('users.title') }}
                  <v-chip color="primary ml-1">{{ !didLoadedData ? "..." : entitiesStatistics['Users'].count }}</v-chip>
                </v-list-item-title>
                <v-list-item-subtitle>{{ $t('users.description') }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-card-actions>
              <v-btn color="primary" text>{{ $t('buttons.enter') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>

      </v-layout>

      <v-container grid-list-md>
      <v-card
          outlined
          color="green lighten-5"
      >
        <v-card-title style="color: #4caf50">{{ $t('statistics.elements.mostHelpfulTitle') }}</v-card-title>
        <v-card-text>
          <div v-if="!didLoadedMostHelpful">{{ $t('elements.loading') }}</div>
          <template v-else>
            <div  v-if="mostHelpfulTutorials.length !== 0" >
              <p
                  v-for="t in mostHelpfulTutorials" :key="t.faqItemId"
                  v-on:click="$router.push('/tutorials/' + t.faqItemId)"
                  class="tutorial_link"
              >
                {{t.faqItemName }}
                <v-chip color="green" outlined>
                  <v-icon left>mdi-thumb-up</v-icon>
                  {{ t.helpfulFeedback }}
                </v-chip>
              </p>
            </div>
            <div v-else>{{ $t('statistics.elements.noStatistics') }}</div>
            <v-row>
              <v-btn
                  id="show_most_helpful_button"
                  color="green"
                  outlined
                  @click="handleTutorialClick('helpful')"
                  style="margin: 15px"
              >
                {{ $t('statistics.elements.showMore') }}
              </v-btn>
            </v-row>

          </template>
        </v-card-text>
      </v-card>
      </v-container>

      <v-container grid-list-md>
        <v-card
            outlined
            color="red lighten-5"
        >
          <v-card-title style="color: #f44336">{{ $t('statistics.elements.leastHelpfulTitle') }}</v-card-title>
          <v-card-text>
            <div v-if="!didLoadedMostNotHelpful">{{ $t('elements.loading') }}</div>
            <template v-else>
              <div  v-if="mostNotHelpfulTutorials.length !== 0" >
                <p
                    v-for="t in mostNotHelpfulTutorials" :key="t.faqItemId"
                    v-on:click="$router.push('/tutorials/' + t.faqItemId)"
                    class="tutorial_link"
                >
                  {{ t.faqItemName }}
                  <v-chip color="red" outlined>
                    <v-icon left>mdi-thumb-down</v-icon>
                    {{ t.notHelpfulFeedback }}
                  </v-chip>
                </p>
              </div>
              <div v-else>{{ $t('statistics.elements.noStatistics') }}</div>
              <v-row>
                <v-btn
                    id="show_least_helpful_button"
                    color="red"
                    outlined
                    @click="handleTutorialClick('notHelpful')"
                    style="margin: 15px"
                >
                  {{ $t('statistics.elements.showMore') }}
                </v-btn>
              </v-row>
            </template>
          </v-card-text>
        </v-card>
      </v-container>

      <v-container grid-list-md>
        <v-card
            outlined
            color="orange lighten-5"
        >
          <v-card-title style="color: #ff9800">{{ $t('statistics.elements.mostUnfinishedTitle') }}</v-card-title>
          <v-card-text>
            <div v-if="!didLoadedMostUnfinished">{{ $t('elements.loading') }}</div>
            <template v-else>
              <div  v-if="mostUnfinishedTutorials.length !== 0" >
                <p
                    v-for="t in mostUnfinishedTutorials" :key="t.faqItemId"
                    v-on:click="$router.push('/tutorials/' + t.faqItemId)"
                    class="tutorial_link"
                >
                  {{ t.faqItemName }}
                  <v-chip color="orange" outlined >
                    <v-icon left>block</v-icon>
                    {{ t.unfinished }}
                  </v-chip>
                </p>
              </div>
              <div v-else>{{ $t('statistics.elements.noStatistics') }}</div>
              <v-row>
                <v-btn
                    id="show_most_unfinished_button"
                    color="orange"
                    outlined
                    @click="handleTutorialClick('unfinished')"
                    style="margin: 15px"
                >
                  {{ $t('statistics.elements.showMore') }}
                </v-btn>
              </v-row>
            </template>
          </v-card-text>
        </v-card>
      </v-container>

    </v-container>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {config} from "@/config";

export default {

  computed: {
    ...mapGetters('dashboard', ['didLoadedData', 'entitiesStatistics']),
    ...mapGetters('user', ['currentUserCanAccess']),
    ...mapGetters('mostFrequentMessages', ['mostFrequentMessages']),
    ...mapGetters('statistics', ['mostHelpfulTutorials', 'mostNotHelpfulTutorials', 'mostUnfinishedTutorials', 'didLoadedMostHelpful', 'didLoadedMostNotHelpful', 'didLoadedMostUnfinished'])
  },

  mounted() {
    this.loadEntitiesStatistics();
    this.loadMostFrequentMessages();
    this.loadMostHelpful();
    this.loadMostNotHelpful();
    this.loadMostUnfinished();
  },

  methods: {
    getUserMessagesCount() {
      if(this.didLoadedData){
        const rest = this.entitiesStatistics['UserMessagesToBeReviewed'].count - config.mostFrequentUserMessagesNumber
        if(rest <= 0) return 0
        else return rest
      }
    },

    ...mapActions('dashboard', ['loadEntitiesStatistics']),
    ...mapActions('mostFrequentMessages', ['loadMostFrequentMessages']),
    ...mapActions('statistics', ['loadMostHelpful', 'loadMostNotHelpful', 'loadMostUnfinished']),

    handleTutorialClick(value){
      this.$router.push('statistics')
      this.$query.toggleSortTutorials = value
      this.$query.comparativeTutorialsCount = 10
    }
  }
};
</script>

<style>
.theme--dark.v-input {
  color: #ff9800;
}

.v-messages {
  min-height: 0;
}

.tutorial_link:hover {
  cursor: pointer;
  font-weight: bold;
}

</style>
