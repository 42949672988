import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Dashboard from './views/Dashboard.vue'
import Tutorials from './views/Tutorials.vue'
import TutorialDetail from './views/TutorialDetail.vue'
import Login from "./views/Login";
import AuthSection from './AuthSection'
import store from './store/index';
import Tags from "./views/Tags";
import Contacts from "./views/Contacts";
import ContactDetail from "./views/ContactDetail";
import Reports from "./views/Reports";
import Chatbots from "./views/Chatbots.vue";
import ChatbotDetail from "./views/ChatbotDetail.vue";
import UserMessages from "@/views/UserMessages";
import Statistics from "@/views/Statistics";
import { languages } from './config'
import Users from "@/views/Users";
import UserDetail from "@/views/UserDetail";
import i18n from '@/plugins/i18n/i18n';


Vue.use(Router);

const router = new Router({
  base: process.env.BASE_URL,
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/auth',
      name: 'auth',
      component: AuthSection,
      beforeEnter: requireAuth,
      children: [
        {
          path: '/dashboard',
          name: 'dashboard',
          component: Dashboard
        },
        {
          path: '/tutorials',
          name: 'tutorials',
          component: Tutorials, 
          meta: {
            query: {
              'sort': 'string:name',
              'desc': 'bool:false',
              'sortLanguage': 'string:' + languages[0],
              'tag': 'int:null',
              'withoutTranslation': 'bool:',
              'search': 'string:',
            }
          }
        },
        {
          path: '/tutorials/:id',
          name: 'tutorialDetail',
          component: TutorialDetail
        },
        {
          path: '/chatbots',
          name: 'chatbots',
          component: Chatbots
        },
        {
          path: '/chatbots/:id',
          name: 'chatbotDetail',
          component: ChatbotDetail
        },
        {
          path: '/tags',
          name: 'tags',
          component: Tags
        },
        {
          path: '/contacts',
          name: 'contacts',
          component: Contacts,
          meta: {
            query: {
              'sort': 'string:name',
              'desc': 'bool:false',
            }
          }
        },
        {
          path: '/contacts/:id',
          name: 'contactDetail',
          component: ContactDetail
        },
        {
          path: '/reports',
          name: 'reports',
          component: Reports,
          meta: {
            query: {
              'archived': 'int:0',
              'sort': 'string:id',
              'asc': 'bool:false', // cannot used desc because the default cannot be true
              'page': 'int:1',
              'itemsPerPage': 'int:10',
            }
          }
        },
        {
          path: '/users',
          name: 'users',
          component: Users,
          meta: {
            query: {
              'sort': 'string:name',
              'desc': 'bool:false',
            }
          }
        },
        {
          path: '/users/:id',
          name: 'userDetail',
          component: UserDetail
        },
        {
          path: '/user-messages',
          name: 'userMessages',
          component: UserMessages,
          meta: {
            query: {
              'search': 'string:',
            }
          },
        },
        {
          path: '/statistics',
          name: 'statistics',
          component: Statistics,
          meta: {
            query: {
              'search': 'string:',
              'tag': 'int:null',
              'toggleSortTutorials': "string:helpful",
              'count': 'int:10',
            }
          },
        },
      ]
    }
  ],
});

function requireAuth(to, from, next) {
  const token = store.state.user.token;

  if (token === null) {
    next({path: '/login', params: {nextUrl: to.fullPath}});
    return;
  }

  if (!store.state.user.didLoadedUserData) {
    store.dispatch('user/downloadUserData')
        .catch(() => {
          next({path: '/login', params: {nextUrl: to.fullPath}});
        });
  }

  next();
}

router.beforeEach((to, from, next) => {
  if (store.state.changeTracking.hasChanges) {
    if (confirm(i18n.t('elements.leaveWithoutSaveConfirm2'))) {
      store.dispatch('changeTracking/resolveChanges')
          .then(() => next());
    } else {
      next(false);
    }
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  store.dispatch('history/recordRoute', from);
});

window.addEventListener('beforeunload', e => {
  if (store.state.changeTracking.hasChanges) {
    e.returnValue = i18n.t('elements.leaveWithoutSaveConfirm2');
  }
});


export default router;
