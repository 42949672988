import axios from "axios";
import {config} from '../../config';
import {normalize, schema} from "normalizr";
import Vue from "vue";

const state = {
    didLoadedMessages: false,
    didLoadedGroupedMessages: false,

    messages: [],
    groupedMessages: [],
    messagesByFaqId: [],
    mostFrequentMessages: [],
};

const message = new schema.Entity('userMessages')

const getters = {
    didLoadedMessages: state => state.didLoadedMessages,

    didLoadedGroupedMessages: state => state.didLoadedGroupedMessages,


    messages: (state) => {
        return state.messages
    },

    groupedUserMessages: (state) => {
        return state.groupedMessages
    },

    messagesByFaqId: (state) => {
      return state.messagesByFaqId
    },

    mostFrequentMessages: (state) => {
      return state.mostFrequentMessages
    },

    messagesCount: (state) => {
        return getters.messages(state).length;
    },

    messageById: (state) => (id) => {
        return state.messages.find(i => i.id === id)
    },


}

const actions = {
    async loadAllMessages({commit}, onComplete = () => {
    }) {
        commit('didLoadedMessages', false)
        axios.get(config.serverUrl + 'chatbot/stats/user-messages/')
            .then((response) => {
                commit('setMessages', response.data.items);
                commit('didLoadedMessages', true);
                onComplete();
            });
    },

    async loadGroupedMessages({commit}){
        commit('didLoadedGroupedMessages', false)
        const response = await axios.get(config.serverUrl + 'chatbot/stats/user-messages/grouped/')
        commit('setGroupedMessages', response.data.items);
        commit('didLoadedGroupedMessages', true);
    },

    async loadAllMessagesByFaqId({commit}, {faqId}) {
        const response = await axios.get(config.serverUrl + `chatbot/stats/user-messages/byFaqItemId/${faqId}`)
        commit('setMessagesByFaqId', response.data.items);
    },

    async loadMostFrequentMessages({commit}){
        const response = await axios.get(config.serverUrl + `chatbot/stats/user-messages/mostFrequent/${config.mostFrequentUserMessagesNumber}`)
        commit('setMostFrequentMessages', response.data.items);
    },

    async updateUserMessage({commit}, {userMessage}) {
        const {id, text, archived} = userMessage;
        const updatedUserMessageDto = {id, text, archived}

        try {
            const response = await axios.put(config.serverUrl + `chatbot/stats/user-messages/${id}`, updatedUserMessageDto);
            const updatedUserMessage = response.data.content;
            commit('updateUserMessage', updatedUserMessage);
            commit('updateGroupedUserMessages', updatedUserMessage);
            commit('updateMessagesByFaqId', updatedUserMessage);
            return updatedUserMessage;
        } catch (e) {
            console.log(e)
        }
    },

    async archiveToBeArchivedMessages({ dispatch, state }) {
        for (let userMessage of state.messagesByFaqId) {
            if (userMessage.toBeArchived === true) {
                let updatedUserMessage = {
                    ...userMessage,
                    archived: true
                }
                if("toBeArchived" in updatedUserMessage) delete updatedUserMessage.toBeArchived

                await dispatch('updateUserMessage', {userMessage: updatedUserMessage});
            }
        }
    }
};

const mutations = {
    didLoadedMessages(state, loaded) {
        state.didLoadedMessages = loaded
    },

    didLoadedGroupedMessages(state, loaded) {
        state.didLoadedGroupedMessages = loaded
    },

    setMessages(state, messages) {
        const normalized = normalize(messages, [message])
        if (normalized.entities !== undefined && normalized.entities.userMessages !== undefined) {
            state.messages = normalized.entities.userMessages
        } else {
            state.messages = {};
        }
    },

    setGroupedMessages(state, groupedMessages) {
        state.groupedMessages = groupedMessages.map(tutorial => ({
            ...tutorial,
            messages: tutorial.messages.filter(message => message.archived === false)
        }));

    },

    setMessagesByFaqId(state, messagesByFaqId){
        state.messagesByFaqId = messagesByFaqId.map(message => ({
            ...message,
            toBeArchived: false,
        })).filter(message => message.archived === false)
            .sort((a, b) => b.count - a.count);
    },

    setMostFrequentMessages(state, messages) {
      state.mostFrequentMessages = messages.filter(mess => mess.count > config.mostFrequentUserMessagesNumber)
    },

    updateUserMessage(state, userMessage) {
        Vue.set(state.messages, userMessage.id, userMessage)
    },

    updateGroupedUserMessages(state, userMessage) {
        if(userMessage.faqItemId === null) {
            state.groupedMessages.forEach(tutorial => {
                if(tutorial.faqItemId < 0 && tutorial.language === userMessage.language) {
                    tutorial.messages.forEach(message => {
                        if(message.id === userMessage.id) {
                            message.archived = userMessage.archived
                        }
                    })
                }
            })
        } else {
            state.groupedMessages.forEach(tutorial => {
                if (tutorial.faqItemId === userMessage.faqItemId) {
                    tutorial.messages.forEach(message => {
                        if(message.id === userMessage.id) {
                            message.archived = userMessage.archived
                        }
                    })
                }
            })
        }

        state.groupedMessages = state.groupedMessages.map(tutorial => ({
            ...tutorial,
            messages: tutorial.messages.filter(message => message.archived === false)
        }));
    },

    updateMessagesByFaqId(state, userMessage) {
        state.messagesByFaqId.forEach(message => {
            if(message.id === userMessage.id) {
                message.archived = userMessage.archived
            }
        })
        state.messagesByFaqId = state.messagesByFaqId.filter(message => message.archived === false)
    },

    setToBeArchived(state, {userMessageId, value}){
        const index = state.messagesByFaqId.findIndex(m => m.id === userMessageId)
        state.messagesByFaqId.at(index).toBeArchived = value
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}