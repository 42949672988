<template>
  <div>
    <floating-loading :shown="!didLoadedGroupedMessages"></floating-loading>
    <floating-loading :shown="!didLoadedData"></floating-loading>

    <h1>{{ $t('userMessages.title') }}</h1>

    <div v-if="groupedUserMessages.length === 0"> {{ $t('userMessages.elements.noMessages') }}</div>

    <v-container>
      <div>
        <h5>{{ $t('userMessages.filter.filterTutorials') }}</h5>
        <v-row>
          <v-col
              cols="12"
              sm="5"
          >
            <v-text-field
                id="search_tutorials_input"
                v-model="querySearchValue"
                :label="$t('userMessages.elements.searchPlaceholder')"
                clearable
                prepend-icon="search"
                :append-icon="querySearchValue && querySearchValue !== $query.search ? 'check' : null"
                @click:append="applyQueryFilter()"
                @click:clear="querySearchValue = ''; applyQueryFilter()"
                @keypress.enter="applyQueryFilter()"
                @blur="applyQueryFilter()"
                style="padding-left: 10px; padding-right: 5px;"
            />
          </v-col>
          <v-col
              cols="12"
              sm="3"
          >
            <v-select
                data-cy="sort_tutorials"
                v-model="tutorialsSortBy"
                :items="tutorialsSortByItems"
                :label="$t('userMessages.filter.sortTutorials')"
                :prepend-icon="tutorialsSortAscending ? 'arrow_upward' : 'arrow_downward'"
                @click:prepend="tutorialsSortAscending = !tutorialsSortAscending"
                style="padding-right: 5px"
            >
            </v-select>
          </v-col>
          <v-col
              cols="12"
              sm="4"
          >
            <v-radio-group
                id="language_filter"
                v-model="filterByLanguage"
                row
            >
              <v-radio
                  :label="$t('userMessages.filter.allLanguages')"
                  value="all"
                  id="all"
              ></v-radio>
              <v-radio
                  v-for="item in languages"
                  :key="item"
                  :value="item"
                  :id="item"
              >
                <template v-slot:label>
              <span
                  :class="`icon-flag sort-language ${item} active`"
              />
                </template>
              </v-radio>
            </v-radio-group>

          </v-col>

        </v-row>
        <v-row>

        </v-row>

        <h5>{{ $t('userMessages.filter.filterMessages') }}</h5>
        <v-col
            cols="12"
            sm="4"
        >
          <v-row>
            <v-checkbox
                class="min_count_checkbox"
                :label="$t('userMessages.filter.moreThan')"
                v-model="enableFilterByCount"
                style="padding-left: 10px; padding-right: 10px"
            ></v-checkbox>
            <v-text-field
                id="min_count_input"
                v-model="filterCount"
                :rules="filterCountRules"
                :disabled="!enableFilterByCount"
                maxlength="5"
                class="filterCount"
                single-line
            ></v-text-field>
          </v-row>
        </v-col>
        <p style="padding-left: 10px;"> {{`${$t('userMessages.numberOfDisplayedMessages')} ${filteredUserMessagesNumber}/${userMessagesNumber}`}}</p>

      </div>

      <h3 style="padding-top: 1rem">{{ $t('userMessages.titleForUncategorizedMessages') }}</h3>
      <div v-if="uncategorizedMessagesArray.length === 0" style="padding-top: 1rem; padding-bottom: 1rem" > {{ $t('userMessages.elements.noUncategorizedMessages') }}</div>
      <div v-else>
      <v-expansion-panels focusable id="uncategorized_expansion_panels">
        <v-expansion-panel
            v-for="tutorial in uncategorizedMessagesArray"
            :key="tutorial.faqItemId"
        >
          <v-expansion-panel-header>
            <span :class="`icon-flag ${tutorial.language} smaller`"></span>
            {{ $t('userMessages.elements.uncategorizedMessages') }}
            <v-chip
                class="ma-2"
                color="primary"
                small
                outlined
            >
              {{ tutorial.messages.length }}
            </v-chip>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list class="panel-content">
              <v-list-item
                  v-for="(message, messageIndex) in tutorial.messages"
                  :key="messageIndex"
              >
                <v-list-item-icon
                    v-if="currentUserCanAccess('userMessages')"
                    class="panel-content-action-icons"
                >
                  <v-col
                      cols="12"
                      sm="6"
                  >
                    <v-btn
                        id="open_dialog_button"
                        class="material-symbols-outlined"
                        fab
                        dark
                        x-small
                        color="primary"
                        @click="openDialog(message, tutorial)"
                    >
                      <v-icon dark>
                        input
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="6"
                  >
                    <v-btn
                        id="archive_button"
                        class="material-symbols-outlined"
                        fab
                        dark
                        x-small
                        color="grey"
                        @click="archive(message, tutorial)"
                    >
                      <v-icon dark>
                        close
                      </v-icon>
                    </v-btn>
                  </v-col>

                </v-list-item-icon>
                <v-list-item-content v-on:click="openDialog(message, tutorial)" class="click-content">
                  <v-list-item-title style="font-weight: bold; display: block" v-text="message.count + 'x '"/>
                  <v-list-item-title v-text="message.text" style="white-space: pre-wrap"/>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      </div>

      <h3>{{ $t('userMessages.titleForPredictedMessages') }}</h3>
      <div v-if="filteredMessages.length === 0" style="padding-top: 1rem; padding-bottom: 1rem"> {{ $t('userMessages.elements.noMessages') }}</div>
      <div v-else>
        <v-expansion-panels focusable id="categorized_expansion_panels">
          <v-expansion-panel
              class="categorized-expansion-panel"
              v-for="tutorial in filteredMessages"
              :key="tutorial.faqItemId"
          >
            <v-expansion-panel-header class="categorized-expansion-panel-header" v-if="tutorial.faqItemName !== uncategorizedMessages">
              <span :class="`icon-flag ${tutorial.language} smaller`"></span>
              {{ tutorial.faqItemName }}
              <v-chip
                  class="ma-2"
                  color="primary"
                  small
                  outlined
              >
                {{ tutorial.messages.length }}
              </v-chip>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list>
                <v-list-item
                    v-for="(message, messageIndex) in tutorial.messages"
                    :key="messageIndex"
                >
                  <v-list-item-icon
                      v-if="currentUserCanAccess('userMessages')"
                  >
                    <v-col
                        cols="12"
                        sm="6"
                    >
                      <v-btn
                          class="material-symbols-outlined"
                          fab
                          dark
                          x-small
                          color="primary"
                          @click="openDialog(message, tutorial)"
                      >
                        <v-icon dark>
                          input
                        </v-icon>
                      </v-btn>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                    >
                      <v-btn
                          class="material-symbols-outlined"
                          fab
                          dark
                          x-small
                          color="grey"
                          @click="archive(message, tutorial)"
                      >
                        <v-icon dark>
                          close
                        </v-icon>
                      </v-btn>
                    </v-col>

                  </v-list-item-icon>
                  <v-list-item-content v-on:click="openDialog(message, tutorial)" class="click-content">
                    <v-list-item-title style="font-weight: bold; display: block" v-text="message.count + 'x '"/>
                    <v-list-item-title v-text="message.text" style="white-space: pre-wrap"/>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </v-container>
    <AssignUserMessageDialog
        id="assign_user_message_dialog"
        :model="isModalVisible"
        :userMessage="selectedUserMessage"
        :newIntent="selectedUserMessageText"
        :predictedTutorialId="predictedTutorialId"
        @close="isModalVisible = false"
    />

    <v-snackbar
        id="message_archived_snackbar"
        v-model="snackbar"
        :timeout="timeout"
    >
      {{ snackbarMessage }}
    </v-snackbar>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import FloatingLoading from "@/components/FloatingLoading";
import AssignUserMessageDialog from "@/components/AssignUserMessageDialog";
import {languages} from '../config';
import {sanitize} from "@/utils/common";
import {nlpUtils} from "@/utils/nlp";

export default {
  name: "UserMessages",
  components: {FloatingLoading, AssignUserMessageDialog},
  mounted() {
    this.loadGroupedMessages();
    this.setSelectItems();
  },

  data() {
    return {
      querySearchValue: this.$query.search,

      isModalVisible: false,
      selectedUserMessage: {},
      selectedUserMessageText: "",
      predictedTutorialId: undefined,

      tutorialsSortAscending: false,

      snackbar: false,
      snackbarMessage: "",
      timeout: 3000,

      // filter constants
      filterCount: 4,
      enableFilterByCount: true,
      uncategorizedMessages: "Uncategorized messages",
      filterByLanguage: "all",

      name: null,
      count: null,
      tutorialsSortByItems: [],
      tutorialsSortBy: null,

      filterCountRules: [
          value => value > 0 || this.$t('userMessages.filter.invalidInput')
      ]
    }
  },
  methods: {
    ...mapActions('groupedUserMessages', ['loadGroupedMessages']),
    ...mapActions('userMessages', ['updateUserMessage']),

    applyQueryFilter() {
      this.$query.search = this.querySearchValue;
    },

    sumMessages(messages) {
      let sum = 0

      messages.forEach(mess => {
        sum += mess.count
      })

      return sum
    },

    selectUserMessage(userMessage, tutorial) {
      let faqItemId = tutorial.faqItemId < 0 ? null : tutorial.faqItemId
      let faqItemName = tutorial.faqItemId < 0 ? null : tutorial.faqItemName

      this.selectedUserMessage = {
        id: userMessage.id,
        text: userMessage.text,
        count: userMessage.count,
        language: userMessage.language,
        faqItemId: faqItemId,
        faqItemName: faqItemName,
        archived: userMessage.archived,
      };

      this.selectedUserMessageText = userMessage.text
    },

    openDialog(userMessage, tutorial){
      this.selectUserMessage(userMessage, tutorial)
      this.predictedTutorialId = tutorial.faqItemId;
      this.isModalVisible = true;
    },

    showSnackbar(message) {
      const shortMessage = message.length > 30 ? message.substring(0, 30) + "..." : message;
      console.log(shortMessage)
      this.snackbarMessage = this.$t('userMessages.snackBarText', {text: shortMessage});
      this.snackbar = true;
    },

    async archive(userMessage, tutorial) {
        this.selectUserMessage(userMessage, tutorial)
        this.selectedUserMessage.archived = true;
        await this.updateUserMessage({userMessage: this.selectedUserMessage})
        this.showSnackbar(userMessage.text)
    },

    setSelectItems(){
      this.name = this.$t('userMessages.filter.title');
      this.count = this.$t('userMessages.filter.count');
      this.tutorialsSortByItems = [this.name, this.count];
      this.tutorialsSortBy = this.$t('userMessages.filter.count');
    },

  },

  computed: {
    didLoadedGroupedMessages() {
      return this.$store.state.groupedUserMessages.didLoadedGroupedMessages
    },

    ...mapGetters('groupedUserMessages', ['groupedUserMessages']),
    ...mapGetters('user', ['currentUserCanAccess']),
    ...mapGetters('tutorials', ['didLoadedData']),

    languages() {
      return languages
    },

    uncategorizedMessagesArray() {
      return this.filteredMessages.filter(item => item.faqItemName === this.uncategorizedMessages)
    },

    filteredMessages() {
      let result = this.groupedUserMessages.slice();

      // do not show tutorials with no user messages
      result = result.filter(tutorial => this.sumMessages(tutorial.messages) > 0)

      // sort messages by number of occurrences in descending order
      result = result.map (data => ({
        ...data,
        messages: [...data.messages].sort((a, b) => b.count - a.count)
      }))

      // filter tutorials by free text
      if (this.$query.search) {
        const requiredTokens = nlpUtils.tokenizeWithoutWhitespaces(this.$query.search);
        result = result.filter(item => requiredTokens.every(token => sanitize(item.faqItemName).includes(sanitize(token))))
      }

      // filter tutorials by language
      if(this.filterByLanguage === 'uk') {
        result = result.filter(item => item.language === 'uk')
      }

      if(this.filterByLanguage === 'en') {
        result = result.filter(item => item.language === 'en')
      }

      if(this.filterByLanguage === 'ru') {
        result = result.filter(item => item.language === 'ru')
      }

      // filter messages by number of occurrences
      if (this.enableFilterByCount && this.filterCount > 0) {
        result = result.map(data => ({
          ...data,
          messages: data.messages.filter(item => item.count >= this.filterCount)
        })).filter(data => data.messages.length > 0)
      }

      // sort tutorials alphabetically
      if(this.tutorialsSortBy === this.name) {
        if(this.tutorialsSortAscending) {
          result.sort((a, b) => a.faqItemName.localeCompare(b.faqItemName))
        } else {
          result.sort((a, b) => b.faqItemName.localeCompare(a.faqItemName))
        }
      }

      // sort tutorials by number of messages
      if(this.tutorialsSortBy === this.count) {
        if(this.tutorialsSortAscending) {
          result = result.sort((a, b) => {
            const sumA = a.messages.length
            const sumB = b.messages.length
            return sumA - sumB
          })
        } else {
          result = result.sort((a, b) => {
            const sumA = a.messages.length
            const sumB = b.messages.length
            return sumB - sumA
          })
        }
      }

      return result;
    },

    filteredUserMessagesNumber(){
      return this.filteredMessages.reduce((acc, item) => acc + item.messages.length, 0)
    },

    userMessagesNumber(){
      return this.groupedUserMessages.reduce((acc, item) => acc + item.messages.length, 0)
    },

  },

}
</script>

<style scoped>
.icon-flag {
  cursor: pointer;
}

.sort-language:not(.active) {
  opacity: 0.3;
}

.smaller {
  width: 18px;
  height: 14px;
}

.filterCount {
  width: 50px;
  padding-top: 5px;
}

.v-expansion-panel-header > *:not(.v-expansion-panel-header__icon) {
  flex: none;
}
.col-12 {
  padding-bottom: 0;
  padding-top: 0;
}

.v-expansion-panels {
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.v-application p {
  margin-bottom: 0;
}

.click-content {
  cursor: pointer;
}


</style>